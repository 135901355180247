import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormCostSheet, IFormShape } from "../../types/api/RecordTypes";
import { RootState } from "../store";

export interface ITimesheetsToQuery {
  startDate: string;
  endDate: string;
  hours: number;
  user: string;
}

export interface ITimesheetCalculatedHours {
  status: `success` | `error`;
  validHours: number;
  validHoursPerDay: number;
  availableHours: number;
}

export interface IFormQueries {
  costsheet: Omit<IFormCostSheet, "id">;
  costsheetValidation: Record<string, boolean>;
  refetch: boolean;
  costsheetToEdit?: IFormShape;
  activateValidation: boolean;
  timesheetsToQuery?: ITimesheetsToQuery;
  timesheetCalculatedHours?: ITimesheetCalculatedHours;
  formSwitch: boolean;
  dateSwitch: boolean;
  submitting: boolean
  failureSubmitting: boolean
  disableButtons: boolean
  designExport: boolean
}

const initialState: IFormQueries = {
  costsheet: {
    department: "",
    engineer: "",
    project: "",
    date: new Date().toISOString(),
    turnover: 0,
    margin: 0,
    estimatedMargin: 0,
    predictedMargin: 0,
    claims: 0,
    daysUtilized: 0,
    projectValue: 0,
    relaseInWeek: 0,
    releaseInWeek: 0,
    notes: "",
  },
  costsheetValidation: {
    engineer: false,
    project: false,
    date: false,
    turnover: false,
    margin: false,
    estimatedMargin: false,
    predictedMargin: false,
  },
  refetch: false,
  activateValidation: false,
  formSwitch: false,
  dateSwitch: true,
  submitting: false,
  failureSubmitting: false,
  disableButtons: false,
  designExport: false
};

export const formSlice = createSlice({
  name: `formSlice`,
  initialState,
  reducers: {
    setFormState: (
      state: IFormQueries,
      action: PayloadAction<IFormCostSheet>
    ) => {
      state.costsheet = action.payload;
    },
    setCostSheetValidation: (
      state: IFormQueries,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.costsheetValidation = action.payload;
    },
    setRefetch: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.refetch = action.payload;
    },
    setCostSheetToEdit: (
      state: IFormQueries,
      action: PayloadAction<IFormShape>
    ) => {
      state.costsheetToEdit = action.payload;
    },
    setFormReset: (state: IFormQueries, _) => {
      state.costsheet = initialState.costsheet;
      state.costsheetValidation = initialState.costsheetValidation;
      state.refetch = !state.refetch;
      state.activateValidation = false;
    },
    setActivateValidation: (
      state: IFormQueries,
      action: PayloadAction<boolean>
    ) => {
      state.activateValidation = action.payload;
    },
    setTimesheetsToQuery: (
      state: IFormQueries,
      action: PayloadAction<ITimesheetsToQuery>
    ) => {
      state.timesheetsToQuery = action.payload;
    },
    setTimesheetCalculatedHours: (
      state: IFormQueries,
      action: PayloadAction<ITimesheetCalculatedHours>
    ) => {
      state.timesheetCalculatedHours = action.payload;
    },
    setFormSwitch: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.formSwitch = action.payload;
    },
    setDateSwitch: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.dateSwitch = action.payload;
    },
    setSubmitting: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.submitting = action.payload;
    },
    setFailureSubmitting: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.failureSubmitting = action.payload;
    },
    setDisableButtons: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.disableButtons = action.payload;
    },
    setDesignExport: (state: IFormQueries, action: PayloadAction<boolean>) => {
      state.designExport = action.payload;
    },
  },
});

export const {
  setFormState,
  setCostSheetValidation,
  setRefetch,
  setCostSheetToEdit,
  setFormReset,
  setActivateValidation,
  setTimesheetsToQuery,
  setTimesheetCalculatedHours,
  setFormSwitch,
  setDateSwitch,
  setSubmitting,
  setFailureSubmitting,
  setDisableButtons,
  setDesignExport
} = formSlice.actions;

export const selectNavigationQueries = (state: RootState) =>
  state.navigationQuery;

export default formSlice.reducer;
