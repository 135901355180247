export const GetLast26Weeks = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day == 0 ? -3 : 4); // adjust when day is sunday
  const thursday = new Date(today.setDate(diff));
  const last26Thursdays = [];
  for (let i = 0; i < 26; i++) {
    last26Thursdays.push(new Date(thursday.setDate(thursday.getDate() - 7)));
  }

  const last26ThursdaysFormatted = last26Thursdays.map((thursday) => {
    const year = thursday.getFullYear();
    const month =
      thursday.getMonth() + 1 < 10
        ? `0${thursday.getMonth() + 1}`
        : thursday.getMonth() + 1;
    const date =
      thursday.getDate() < 10 ? `0${thursday.getDate()}` : thursday.getDate();
    return `${year}-${month}-${date}`;
  });

  const last26WeeksAsSelectOptions = last26ThursdaysFormatted.map(
    (thursday) => {
      const selectOptions = {
        value: thursday,
        label: thursday,
      };
      return selectOptions;
    }
  );

  const nextThursday = new Date(last26ThursdaysFormatted[0]);
  nextThursday.setDate(nextThursday.getDate() + 7);
  const year = nextThursday.getFullYear();
  const month =
    nextThursday.getMonth() + 1 < 10
      ? `0${nextThursday.getMonth() + 1}`
      : nextThursday.getMonth() + 1;
  const date =
    nextThursday.getDate() < 10
      ? `0${nextThursday.getDate()}`
      : nextThursday.getDate();
  const nextThursdayFormatted = `${year}-${month}-${date}`;

  const nextThursdayAsSelectOption = {
    value: nextThursdayFormatted,
    label: nextThursdayFormatted,
  };

  const last8Weeks = last26ThursdaysFormatted.slice(0, 8);

  return {
    last26Weeks: last26ThursdaysFormatted,
    last26WeeksForSelect: [
      nextThursdayAsSelectOption,
      ...last26WeeksAsSelectOptions,
    ],
    last8Weeks: last8Weeks,
  };
};
