
export const GetLast12Months = () => {
    const today = new Date();
    const last12Months = [];
    for (let i = 0; i < 12; i++) {
        last12Months.push(new Date(today.setMonth(today.getMonth() - 1)));
    }

    const last12MonthsFormatted = last12Months.map((month) => {
        const year = month.getFullYear();
        const monthNumber = month.getMonth() + 1 < 10 ? `0${month.getMonth() + 1}` : month.getMonth() + 1;
        return `${year}-${monthNumber}`;
    });

    const last12MonthsAsSelectOptions = last12MonthsFormatted.map((month) => {
        const selectOptions = {
            value: month,
            label: month,
        }
        return selectOptions
    })

    return {
        last12Months : last12MonthsFormatted,
        last12MonthsForSelect : last12MonthsAsSelectOptions,
    };
}