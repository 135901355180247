import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetLast12Months } from "../../functions/calendar/GetLast12Months";
import { GetLast26Weeks } from "../../functions/calendar/GetLast26Weeks";
import { RootState } from "../store";

const ThisThursday = GetLast26Weeks().last26Weeks[0];
const ThisMonth = GetLast12Months().last12Months[0];

export interface ISelectedChartElement {
  label: string;
  value: string;
}

export interface IFilterState {
  selectedWeek: string;
  selectedMonth: string;
  selectedDept: string;
  selectedChartElement: ISelectedChartElement[];
}

const initialState: IFilterState = {
  selectedWeek: ThisThursday,
  selectedMonth: ThisMonth,
  selectedDept: `All`,
  selectedChartElement: [{ label: "", value: "" }],
};

export const filterSlice = createSlice({
  name: `filterSlice`,
  initialState,
  reducers: {
    setSelectedWeek: (state, action: PayloadAction<string>) => {
      state.selectedWeek = action.payload;
    },
    setSelectedMonth: (state, action: PayloadAction<string>) => {
      state.selectedMonth = action.payload;
    },
    setSelectedDept: (state, action: PayloadAction<string>) => {
      state.selectedDept = action.payload;
    },
    setSelectedChartElement: (
      state,
      action: PayloadAction<ISelectedChartElement>
    ) => {
      if (state.selectedChartElement[0].label === ``) {
        state.selectedChartElement[0] = action.payload;
      } else {
        state.selectedChartElement.push(action.payload);
      }
    },
    resetSelectedChartElement: (state) => {
      state.selectedChartElement = [{ label: "", value: "" }];
    },
  },
});

export const {
  setSelectedWeek,
  setSelectedMonth,
  setSelectedDept,
  setSelectedChartElement,
  resetSelectedChartElement,
} = filterSlice.actions;

export const selectFilterState = (state: RootState) => state.filterState;

export default filterSlice.reducer;
