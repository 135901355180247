import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IPageState {
  status: `create` | `edit`;
  tab: number;
  name: string;
}

const initialPageState: IPageState = {
  status: `create`,
  tab: 0,
  name: `data`,
};

export interface INavigationQueries {
  selectedTab: number;
  selectedCard: string;
  selectedContent: string;
  pageState: IPageState;
  activeSection: number;
  endpoint: string[];
  export: boolean;
}

const initialState: INavigationQueries = {
  selectedTab: 0,
  selectedCard: "hoursLogged",
  selectedContent: "overview",
  pageState: initialPageState,
  activeSection: 1,
  endpoint: [""],
  export: false,
};

export const navigationSlice = createSlice({
  name: `navigationSlice`,
  initialState,
  reducers: {
    setSelectedTab: (
      state: INavigationQueries,
      action: PayloadAction<number>
    ) => {
      state.selectedTab = action.payload;
    },
    setSelectedCard: (
      state: INavigationQueries,
      action: PayloadAction<string>
    ) => {
      state.selectedCard = action.payload;
    },
    setSelectedContent: (
      state: INavigationQueries,
      action: PayloadAction<string>
    ) => {
      state.selectedContent = action.payload;
    },
    setPageState: (
      state: INavigationQueries,
      action: PayloadAction<IPageState>
    ) => {
      state.pageState = action.payload;
    },
    setActiveSection: (
      state: INavigationQueries,
      action: PayloadAction<number>
    ) => {
      state.activeSection = action.payload;
    },
    setNavigationReset: (state: INavigationQueries, _) => {
      state.activeSection = 1;
    },
    setEndpoint: (
      state: INavigationQueries,
      action: PayloadAction<string[]>
    ) => {
      state.endpoint = action.payload;
    },
    setExport: (state: INavigationQueries, action: PayloadAction<boolean>) => {
      state.export = action.payload;
    },
  },
});

export const {
  setSelectedTab,
  setSelectedCard,
  setSelectedContent,
  setPageState,
  setActiveSection,
  setNavigationReset,
  setEndpoint,
  setExport,
} = navigationSlice.actions;

export const selectNavigationQueries = (state: RootState) =>
  state.navigationQuery;

export default navigationSlice.reducer;
