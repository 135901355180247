import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IAccessibilityState {
  gridLines: boolean;
}

const initialState: IAccessibilityState = {
  gridLines: true,
};

export const accessibilitySlice = createSlice({
  name: `accessibilitySlice`,
  initialState,
  reducers: {
    setGridLines: (
      state: IAccessibilityState,
      action: PayloadAction<boolean>
    ) => {
      state.gridLines = action.payload;
    },
  },
});

export const { setGridLines } = accessibilitySlice.actions;

export const selectAccessibilityState = (state: RootState) =>
  state.accessibilityState;

export default accessibilitySlice.reducer;
