import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IOptions } from "../../../components/atoms/Select/Select";
import {
  ICentrumCastSeparatedTypes,
  IDesignRevisions,
  IFormDataFromAPI,
  IGetDataHistory,
  IGetTimesheetData,
  IReportAarsleffData,
  IReportCentrumData,
  IReportHomeData,
  IReportOverviewData,
  IReportPipelineData,
  IReportPlantData,
  ITimesheetExport,
  ITimesheetIndex,
  ITimesheetKpis,
  ITimesheetReportData,
  ITimesheetReportPeople,
  IUser,
} from "../../../types/api/RecordTypes";
import { ITimesheetCalculatedHours } from "../../forms/formSlice";

export const apiSlice = createApi({
  reducerPath: `api`,
  baseQuery: fetchBaseQuery({ baseUrl: `/` }),
  endpoints: (builder) => ({
    getCostSheets: builder.query<ICentrumCastSeparatedTypes[], string>({
      query: (query) => `api/costsheet/get`,
    }),
    getCostSheetsHistory: builder.query<IGetDataHistory[], string>({
      query: (query) => `api/costsheet/get?type=history`,
    }),
    getCentrumCast: builder.query<ICentrumCastSeparatedTypes[], string>({
      query: (query) => `api/centrum/cast/get`,
    }),
    getCentrumCastHistory: builder.query<IGetDataHistory[], string>({
      query: (query) => `api/centrum/cast/get?type=history`,
    }),
    getFormData: builder.query<IFormDataFromAPI[], string>({
      query: (query) => `api/${query}`,
    }),
    getFormHistory: builder.query<IGetDataHistory[], string>({
      query: (query) => `api/${query}`,
    }),
    getOverviewReportData: builder.query<IReportOverviewData, string>({
      query: (query) => `api/report/overview/?${query}`,
    }),
    getAarsleffReportData: builder.query<IReportAarsleffData, string>({
      query: (query) => `api/report/aarsleff/?${query}`,
    }),
    getPlantReportData: builder.query<IReportPlantData, string>({
      query: (query) => `api/report/plant/?${query}`,
    }),
    getCentrumReportData: builder.query<IReportCentrumData, string>({
      query: (query) => `api/report/centrum/?${query}`,
    }),
    getPipelineReportData: builder.query<IReportPipelineData, string>({
      query: (query) => `api/report/pipeline/?${query}`,
    }),
    getHomeData: builder.query<IReportHomeData, string>({
      query: (query) => `api/report/home/?${query}`,
    }),
    getJobs: builder.query<IOptions[], string>({
      query: (query) => `api/jobs/get?${query}`,
    }),
    getAllJobs: builder.query<IOptions[], string>({
      query: (query) => `api/design/jobs/get?${query}`,
    }),
    getUsers: builder.query<IOptions[], string>({
      query: (query) => `api/users/get?${query}`,
    }),
    getValidTimesheetHours: builder.query<ITimesheetCalculatedHours, string>({
      query: (query) => `api/timesheets/validhours?${query}`,
    }),
    getTimesheetAdminPanel: builder.query<ITimesheetIndex, string>({
      query: (query) => `api/timesheets/index/get?${query}`,
    }),
    getTimesheetExportData: builder.query<ITimesheetExport, string>({
      query: (query) => `api/timesheets/index/export?${query}`,
    }),
    getJobDescription: builder.query<string, string>({
      query: (query) => `api/general/jobDescription?${query}`,
    }),
    getJobReportData: builder.query<ITimesheetReportData, string>({
      query: (query) => `api/timesheets/reports/job/get?${query}`,
    }),
    getPeopleReportData: builder.query<ITimesheetReportPeople, string>({
      query: (query) => `api/timesheets/reports/people/get?${query}`,
    }),
    getTimesheetHistory: builder.query<IGetDataHistory[], string>({
      query: (query) => `api/timesheets/engineers/get?${query}`,
    }),
    getTimesheetData: builder.query<IGetTimesheetData, string>({
      query: (query) => `api/timesheets/engineers/get?${query}`,
    }),
    getJobsInTimesheet: builder.query<IOptions[], string>({
      query: (query) => `api/timesheets/reports/get?${query}`,
    }),
    getDesignRevisions: builder.query<IDesignRevisions, string>({
      query: (query) => `api/design/revisions/get?${query}`,
    }),
    getUserData: builder.query<IUser, string>({
      query: (query) => `api/user/get?${query}`,
    }),
    getTimesheetKpis: builder.query<ITimesheetKpis, string>({
      query: (query) => `api/timesheets/kpi/get?${query}`,
    }),
  }),
});

export const {
  useGetDesignRevisionsQuery,
  useGetJobsInTimesheetQuery,
  useGetTimesheetDataQuery,
  useGetTimesheetHistoryQuery,
  useGetPeopleReportDataQuery,
  useGetJobReportDataQuery,
  useGetJobDescriptionQuery,
  useGetTimesheetExportDataQuery,
  useGetCostSheetsQuery,
  useGetCostSheetsHistoryQuery,
  useGetCentrumCastQuery,
  useGetCentrumCastHistoryQuery,
  useGetFormDataQuery,
  useGetFormHistoryQuery,
  useGetOverviewReportDataQuery,
  useGetAarsleffReportDataQuery,
  useGetPlantReportDataQuery,
  useGetCentrumReportDataQuery,
  useGetPipelineReportDataQuery,
  useGetHomeDataQuery,
  useGetJobsQuery,
  useGetUsersQuery,
  useGetValidTimesheetHoursQuery,
  useGetTimesheetAdminPanelQuery,
  useGetAllJobsQuery,
  useGetUserDataQuery,
  useGetTimesheetKpisQuery,
} = apiSlice;
