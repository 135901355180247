import { configureStore } from "@reduxjs/toolkit"
import navigationQueryReducer from "./navigation/navigationSlice"
import accessibilityReducer from "./accessibility/accessibilitySlice"
import formReducer from "./forms/formSlice"
import filterReducer from "./filters/filterSlice"
import { apiSlice } from "./features/api/apiSlice"


// ...

export const store = configureStore({
  reducer: {
    navigationQuery: navigationQueryReducer,
    accessibilityState: accessibilityReducer,
    filterState: filterReducer,
    formState: formReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch